import React from "react";

export const TanStackRouterDevtools =
  import.meta.env.MODE === "production"
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import("@tanstack/router-devtools").then((resource) => ({
          default: resource.TanStackRouterDevtools,
        })),
      );
