import { Avatar, Group, rem, Text, UnstyledButton } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { forwardRef } from "react";
import type { IUserButton } from "./interfaces";

export const UserButton = forwardRef<HTMLButtonElement, IUserButton>(
  (
    { email, name, url, hideChevron = false, ...others }: IUserButton,
    reference,
  ) => {
    return (
      <UnstyledButton
        ref={reference}
        style={{ padding: "1em", width: "100%" }}
        {...others}
      >
        <Group>
          <Avatar radius="xl" src={url} />

          <div style={{ flex: 1 }}>
            <Text fw={500} size="sm">
              {name}
            </Text>

            <Text c="dimmed" size="xs">
              {email}
            </Text>
          </div>
          {!hideChevron && (
            <IconChevronRight
              stroke={1.5}
              style={{ height: rem(14), width: rem(14) }}
            />
          )}
        </Group>
      </UnstyledButton>
    );
  },
);

UserButton.displayName = "UserButton";
