import { Image, Skeleton as MS } from "@mantine/core";
import type { FC } from "react";
import Styles from "../styles.module.scss";
import type { ISkeleton } from "./interrfaces";

export const Skeleton: FC<ISkeleton> = ({ thumbnailUrl }) => {
  return (
    <div className={Styles.details}>
      <h1>
        <MS height={"2em"} width={"85%"} />
      </h1>
      <div className={Styles.topPart}>
        <Image
          alt={"Cover image loading placeholder"}
          fit={"contain"}
          h={400}
          radius={"md"}
          src={thumbnailUrl}
          w={400}
        />
        <div className={Styles.playInfo}>
          <MS height={"100%"} width={"100%"} />
        </div>
      </div>
      <MS height={"1em"} radius="xl" />
      <MS height={"1em"} mt={6} radius="xl" />
      <MS height={"1em"} mt={6} radius="xl" width="70%" />
      <MS height={"1em"} mt={6} radius="xl" width="20%" />
    </div>
  );
};
