import type { FC } from "react";
import { useGetBoardGameQuery } from "#/graphql/graphql";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDebounceCallback } from "usehooks-ts";
import { BoardGameDetails as BoardGameDetailsComponent } from "#components/BoardGameDetails";
import { Skeleton } from "#components/BoardGameDetails/Skeleton";

interface IBoardGameDetails {
  cloudinaryPublicId: string;
  thumbnailUrl: string;
  uuid: string;
}

export const BoardGameDetails: FC<IBoardGameDetails> = ({
  cloudinaryPublicId,
  thumbnailUrl,
  uuid,
}) => {
  const { data, error, isLoading, refetch } = useGetBoardGameQuery({
    uuid,
  });
  const debouncedRefetch = useDebounceCallback(refetch, 1000);

  if (error) {
    debouncedRefetch();
  }
  console.log("Hello???");
  console.log({ data, error, isLoading, refetch });

  return isLoading || !data?.boardGame ? (
    <Skeleton
      thumbnailUrl={
        `https://res.cloudinary.com/govannon/c_limit,f_auto,h_400,q_auto,w_400/v1734947498/${cloudinaryPublicId}` ||
        thumbnailUrl
      }
    />
  ) : (
    <BoardGameDetailsComponent {...data.boardGame} />
  );
};
