import { DonutChart } from "@mantine/charts";
import { ColorSwatch, Skeleton, Table } from "@mantine/core";
import type { FC } from "react";

import { COLORS } from "./helpers";
import type { IStatsDonut } from "./interfaces";
import Styles from "./styles.module.scss";

export const StatsDonut: FC<IStatsDonut> = ({ data, isLoading = false }) => {
  if (isLoading) {
    return <Skeleton height={200} />;
  }

  const pieData = data.map((item, index) => ({
    color: COLORS[index],
    name: item.mechanic ?? "",
    value: item.count,
  }));

  return (
    <div className={Styles.chartContainer}>
      <DonutChart data={pieData} />
      <Table>
        <Table.Tbody>
          {data?.map((element, index) => (
            <Table.Tr key={element.mechanic}>
              <Table.Td>
                <ColorSwatch
                  color={`var(--mantine-color-${COLORS[index]}-9)`}
                  radius={"md"}
                />
              </Table.Td>
              <Table.Td>{element.mechanic}</Table.Td>
              <Table.Td>{element.count}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </div>
  );
};
