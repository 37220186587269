import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useWindowEvent } from "@mantine/hooks";
import { type FC, useCallback, useEffect, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { ScreenSizes, useScreenSize } from "#contexts/ScreenSizeContext";
import type { IShelf } from "./interfaces";

import Styles from "./styles.module.scss";

export const Shelf: FC<IShelf> = ({ itemHeight = 150, items = [] }) => {
  const { screenSize } = useScreenSize();
  const padding = screenSize === ScreenSizes.XS ? 20 : 30;
  const [settings, setSettings] = useState({ itemsPerRow: 0, totalRows: 0 });
  const [animationParentShelves, shelvesAnimationEnabled] = useAutoAnimate();
  const [animationParentShelf, shelfAnimationEnabled] = useAutoAnimate();

  const calculateShelfSize = useCallback(() => {
    shelfAnimationEnabled(false);
    shelvesAnimationEnabled(false);
    const shelfContent = document.querySelector("#shelves");
    if (!shelfContent) {
      return;
    }

    let perRow = Math.floor(shelfContent?.clientWidth / (itemHeight + padding));
    if (perRow < 1 || Number.isNaN(perRow)) {
      perRow = 1;
    }

    setSettings({
      itemsPerRow: perRow,
      totalRows: Math.ceil(items.length / perRow),
    });
  }, [
    items.length,
    itemHeight,
    shelfAnimationEnabled,
    shelvesAnimationEnabled,
    padding,
  ]);

  const calculateShelfSizeDebounced = useCallback(
    useDebounceCallback(calculateShelfSize, 50, { trailing: true }),
    [],
  );

  useWindowEvent("resize", calculateShelfSizeDebounced);

  useEffect(() => {
    calculateShelfSize();
  }, [calculateShelfSize]);

  const renderItems = () => {
    const { itemsPerRow, totalRows } = settings;

    if (totalRows <= 0) {
      return null;
    }

    return Array.from({ length: totalRows }, (_, rowIndex) => {
      const startIndex = rowIndex * itemsPerRow;
      const endIndex = startIndex + itemsPerRow;
      const rowItems = items.slice(startIndex, endIndex);
      return (
        // biome-ignore lint/suspicious/noArrayIndexKey: I have no idea what to use instead of the shelf number here
        <div key={`shelf-${rowIndex}`}>
          <div className={Styles.container} ref={animationParentShelf}>
            {rowItems.map((item) => (
              <div className={Styles.itemContainer} key={`item-${item.key}`}>
                <div className={Styles.item}>{item}</div>
              </div>
            ))}
          </div>
          <div className={Styles.shelf} />
        </div>
      );
    });
  };

  return (
    <div>
      <div id="shelves" ref={animationParentShelves}>
        {renderItems()}
      </div>
    </div>
  );
};
