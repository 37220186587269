import { Container, Paper, Stack, Text, Title } from "@mantine/core";

export const PrivacyScreen = () => {
  return (
    <Container size="md" py="xl">
      <Paper p="xl" radius="md" withBorder={true}>
        <Stack>
          <Title order={1}>Privacy Policy</Title>

          <Text>Last updated: {new Date().toLocaleDateString()}</Text>

          <Title order={2}>1. Information We Collect</Title>
          <Text>
            We collect information that you provide directly to us, information
            we obtain automatically when you use our service, and information
            from third party sources.
          </Text>

          <Title order={2}>2. How We Use Your Information</Title>
          <Text>
            We use the information we collect to provide, maintain, and improve
            our services, to develop new ones, and to protect our company and
            our users.
          </Text>

          <Title order={2}>3. Information Sharing and Disclosure</Title>
          <Text>
            We do not share your personal information with companies,
            organizations, or individuals outside of our company except in the
            following cases: - With your consent - For legal reasons - To
            protect rights and safety
          </Text>

          <Title order={2}>4. Data Security</Title>
          <Text>
            We work hard to protect our users from unauthorized access to or
            unauthorized alteration, disclosure, or destruction of information
            we hold.
          </Text>

          <Title order={2}>5. Contact Us</Title>
          <Text>
            If you have any questions about this Privacy Policy, please contact
            us.
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
};
