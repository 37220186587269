import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconBarcode,
  IconCompass,
  IconDice6,
  IconShare,
  IconUsers,
} from "@tabler/icons-react";

import Styles from "./styles.module.scss";

export const AboutScreen = () => {
  const features = [
    {
      icon: IconBarcode,
      title: "Scan & Showcase",
      description:
        "Zap your game's barcode with our app and boom – it's in your digital collection. Each game transforms into a mystical entry in your own enchanted library.",
      color: "violet",
      image: "/images/chess.jpg",
      gradient:
        "linear-gradient(45deg, rgba(76, 61, 179, 0.92), rgba(123, 61, 179, 0.92))",
    },
    {
      icon: IconShare,
      title: "Share the Adventure",
      description:
        "Got an awesome collection? Show it off! Share your library with friends, family, or the whole world.",
      color: "pink",
      image: "/images/cards.jpg",
      gradient:
        "linear-gradient(45deg, rgba(179, 61, 119, 0.96), rgba(179, 61, 61, 0.96))",
    },
    {
      icon: IconCompass,
      title: "Discover",
      description:
        "Find new games, make connections, and let the world see your epic taste in board games.",
      color: "blue",
      image: "/images/city.jpg",
      gradient:
        "linear-gradient(45deg, rgba(61, 179, 161, 0.80), rgba(61, 123, 179, 0.80))",
    },
    {
      icon: IconUsers,
      title: "Join the Community",
      description:
        "Be part of a community that gets it. Connect with fellow board game enthusiasts and share your passion.",
      color: "teal",
      image: "/images/fantasy.jpg",
      gradient:
        "linear-gradient(45deg, rgba(61, 179, 76, 0.9), rgba(123, 179, 61, 0.9))",
    },
  ];

  return (
    <Container className={Styles.about} size="lg">
      <Stack gap="xl">
        <Box className={Styles.hero} pos="relative">
          <Paper p="xl" radius="md" className={Styles.heroContent}>
            <Stack gap="lg">
              <Title order={1} ta="center">
                About Board Game Library
              </Title>
              <Text size="xl" ta="center" className={Styles.heroText}>
                Your board games. Leveled up. Ever dreamt of turning your board
                game collection into an epic library of magical tomes? With
                Board Game Library, that dream's a reality. 📚✨
              </Text>
            </Stack>
          </Paper>
        </Box>

        <Grid gutter="xl">
          {features.map((feature) => (
            <Grid.Col key={feature.title} span={{ base: 12, sm: 6 }}>
              <Paper
                shadow="md"
                radius="lg"
                p="xl"
                style={{
                  position: "relative",
                  overflow: "hidden",
                  minHeight: "14em",
                  backgroundImage: `${feature.gradient}, url(${feature.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <Box>
                  <ThemeIcon
                    size={48}
                    radius="md"
                    variant="filled"
                    color={feature.color}
                  >
                    <feature.icon size={24} />
                  </ThemeIcon>
                  <Title order={3} mt="md" mb="xs" c="white">
                    {feature.title}
                  </Title>
                  <Text c="white" size="sm" style={{ opacity: 0.9 }}>
                    {feature.description}
                  </Text>
                </Box>
              </Paper>
            </Grid.Col>
          ))}
        </Grid>

        <Paper p="xl" radius="md" className={Styles.callToAction}>
          <Stack gap="md" align="center">
            <Title order={2} c="white">
              Why Wait?{" "}
              <IconDice6
                size={32}
                stroke={1.5}
                style={{ verticalAlign: "middle" }}
              />
            </Title>
            <Text size="lg" ta="center" c="white">
              Join us. Dive into a universe where your board games come alive,
              and your collection grows as you do.
            </Text>
            <Text size="lg" fw={500} ta="center" c="white">
              Board Game Library: Where every game is a new story waiting for
              you.
            </Text>
          </Stack>
        </Paper>
      </Stack>
    </Container>
  );
};
