/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./routes/__root";
import { Route as PublicImport } from "./routes/_public";
import { Route as AuthenticatedImport } from "./routes/_authenticated";
import { Route as PublicIndexImport } from "./routes/_public/index";
import { Route as PublicTosImport } from "./routes/_public/tos";
import { Route as PublicPrivacyImport } from "./routes/_public/privacy";
import { Route as PublicLoginImport } from "./routes/_public/login";
import { Route as PublicLoggedOutImport } from "./routes/_public/loggedOut";
import { Route as PublicForgotPasswordImport } from "./routes/_public/forgot-password";
import { Route as PublicAuthImport } from "./routes/_public/auth";
import { Route as PublicAboutImport } from "./routes/_public/about";
import { Route as AuthenticatedResetPasswordImport } from "./routes/_authenticated/reset-password";
import { Route as AuthenticatedProfileImport } from "./routes/_authenticated/profile";
import { Route as PublicUsersIndexImport } from "./routes/_public/users/index";
import { Route as PublicUsersUserIdImport } from "./routes/_public/users/$userId";

// Create/Update Routes

const PublicRoute = PublicImport.update({
  id: '/_public',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const PublicIndexRoute = PublicIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PublicRoute,
} as any)

const PublicTosRoute = PublicTosImport.update({
  id: '/tos',
  path: '/tos',
  getParentRoute: () => PublicRoute,
} as any)

const PublicPrivacyRoute = PublicPrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => PublicRoute,
} as any)

const PublicLoginRoute = PublicLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => PublicRoute,
} as any)

const PublicLoggedOutRoute = PublicLoggedOutImport.update({
  id: '/loggedOut',
  path: '/loggedOut',
  getParentRoute: () => PublicRoute,
} as any)

const PublicForgotPasswordRoute = PublicForgotPasswordImport.update({
  id: '/forgot-password',
  path: '/forgot-password',
  getParentRoute: () => PublicRoute,
} as any)

const PublicAuthRoute = PublicAuthImport.update({
  id: '/auth',
  path: '/auth',
  getParentRoute: () => PublicRoute,
} as any)

const PublicAboutRoute = PublicAboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => PublicRoute,
} as any)

const AuthenticatedResetPasswordRoute = AuthenticatedResetPasswordImport.update(
  {
    id: '/reset-password',
    path: '/reset-password',
    getParentRoute: () => AuthenticatedRoute,
  } as any,
)

const AuthenticatedProfileRoute = AuthenticatedProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const PublicUsersIndexRoute = PublicUsersIndexImport.update({
  id: '/users/',
  path: '/users/',
  getParentRoute: () => PublicRoute,
} as any)

const PublicUsersUserIdRoute = PublicUsersUserIdImport.update({
  id: '/users/$userId',
  path: '/users/$userId',
  getParentRoute: () => PublicRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_public': {
      id: '/_public'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PublicImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/profile': {
      id: '/_authenticated/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthenticatedProfileImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/reset-password': {
      id: '/_authenticated/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthenticatedResetPasswordImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_public/about': {
      id: '/_public/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof PublicAboutImport
      parentRoute: typeof PublicImport
    }
    '/_public/auth': {
      id: '/_public/auth'
      path: '/auth'
      fullPath: '/auth'
      preLoaderRoute: typeof PublicAuthImport
      parentRoute: typeof PublicImport
    }
    '/_public/forgot-password': {
      id: '/_public/forgot-password'
      path: '/forgot-password'
      fullPath: '/forgot-password'
      preLoaderRoute: typeof PublicForgotPasswordImport
      parentRoute: typeof PublicImport
    }
    '/_public/loggedOut': {
      id: '/_public/loggedOut'
      path: '/loggedOut'
      fullPath: '/loggedOut'
      preLoaderRoute: typeof PublicLoggedOutImport
      parentRoute: typeof PublicImport
    }
    '/_public/login': {
      id: '/_public/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof PublicLoginImport
      parentRoute: typeof PublicImport
    }
    '/_public/privacy': {
      id: '/_public/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof PublicPrivacyImport
      parentRoute: typeof PublicImport
    }
    '/_public/tos': {
      id: '/_public/tos'
      path: '/tos'
      fullPath: '/tos'
      preLoaderRoute: typeof PublicTosImport
      parentRoute: typeof PublicImport
    }
    '/_public/': {
      id: '/_public/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof PublicIndexImport
      parentRoute: typeof PublicImport
    }
    '/_public/users/$userId': {
      id: '/_public/users/$userId'
      path: '/users/$userId'
      fullPath: '/users/$userId'
      preLoaderRoute: typeof PublicUsersUserIdImport
      parentRoute: typeof PublicImport
    }
    '/_public/users/': {
      id: '/_public/users/'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof PublicUsersIndexImport
      parentRoute: typeof PublicImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedRouteChildren {
  AuthenticatedProfileRoute: typeof AuthenticatedProfileRoute
  AuthenticatedResetPasswordRoute: typeof AuthenticatedResetPasswordRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedProfileRoute: AuthenticatedProfileRoute,
  AuthenticatedResetPasswordRoute: AuthenticatedResetPasswordRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

interface PublicRouteChildren {
  PublicAboutRoute: typeof PublicAboutRoute
  PublicAuthRoute: typeof PublicAuthRoute
  PublicForgotPasswordRoute: typeof PublicForgotPasswordRoute
  PublicLoggedOutRoute: typeof PublicLoggedOutRoute
  PublicLoginRoute: typeof PublicLoginRoute
  PublicPrivacyRoute: typeof PublicPrivacyRoute
  PublicTosRoute: typeof PublicTosRoute
  PublicIndexRoute: typeof PublicIndexRoute
  PublicUsersUserIdRoute: typeof PublicUsersUserIdRoute
  PublicUsersIndexRoute: typeof PublicUsersIndexRoute
}

const PublicRouteChildren: PublicRouteChildren = {
  PublicAboutRoute: PublicAboutRoute,
  PublicAuthRoute: PublicAuthRoute,
  PublicForgotPasswordRoute: PublicForgotPasswordRoute,
  PublicLoggedOutRoute: PublicLoggedOutRoute,
  PublicLoginRoute: PublicLoginRoute,
  PublicPrivacyRoute: PublicPrivacyRoute,
  PublicTosRoute: PublicTosRoute,
  PublicIndexRoute: PublicIndexRoute,
  PublicUsersUserIdRoute: PublicUsersUserIdRoute,
  PublicUsersIndexRoute: PublicUsersIndexRoute,
}

const PublicRouteWithChildren =
  PublicRoute._addFileChildren(PublicRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof PublicRouteWithChildren
  '/profile': typeof AuthenticatedProfileRoute
  '/reset-password': typeof AuthenticatedResetPasswordRoute
  '/about': typeof PublicAboutRoute
  '/auth': typeof PublicAuthRoute
  '/forgot-password': typeof PublicForgotPasswordRoute
  '/loggedOut': typeof PublicLoggedOutRoute
  '/login': typeof PublicLoginRoute
  '/privacy': typeof PublicPrivacyRoute
  '/tos': typeof PublicTosRoute
  '/': typeof PublicIndexRoute
  '/users/$userId': typeof PublicUsersUserIdRoute
  '/users': typeof PublicUsersIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedRouteWithChildren
  '/profile': typeof AuthenticatedProfileRoute
  '/reset-password': typeof AuthenticatedResetPasswordRoute
  '/about': typeof PublicAboutRoute
  '/auth': typeof PublicAuthRoute
  '/forgot-password': typeof PublicForgotPasswordRoute
  '/loggedOut': typeof PublicLoggedOutRoute
  '/login': typeof PublicLoginRoute
  '/privacy': typeof PublicPrivacyRoute
  '/tos': typeof PublicTosRoute
  '/': typeof PublicIndexRoute
  '/users/$userId': typeof PublicUsersUserIdRoute
  '/users': typeof PublicUsersIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_public': typeof PublicRouteWithChildren
  '/_authenticated/profile': typeof AuthenticatedProfileRoute
  '/_authenticated/reset-password': typeof AuthenticatedResetPasswordRoute
  '/_public/about': typeof PublicAboutRoute
  '/_public/auth': typeof PublicAuthRoute
  '/_public/forgot-password': typeof PublicForgotPasswordRoute
  '/_public/loggedOut': typeof PublicLoggedOutRoute
  '/_public/login': typeof PublicLoginRoute
  '/_public/privacy': typeof PublicPrivacyRoute
  '/_public/tos': typeof PublicTosRoute
  '/_public/': typeof PublicIndexRoute
  '/_public/users/$userId': typeof PublicUsersUserIdRoute
  '/_public/users/': typeof PublicUsersIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/profile'
    | '/reset-password'
    | '/about'
    | '/auth'
    | '/forgot-password'
    | '/loggedOut'
    | '/login'
    | '/privacy'
    | '/tos'
    | '/'
    | '/users/$userId'
    | '/users'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/profile'
    | '/reset-password'
    | '/about'
    | '/auth'
    | '/forgot-password'
    | '/loggedOut'
    | '/login'
    | '/privacy'
    | '/tos'
    | '/'
    | '/users/$userId'
    | '/users'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_public'
    | '/_authenticated/profile'
    | '/_authenticated/reset-password'
    | '/_public/about'
    | '/_public/auth'
    | '/_public/forgot-password'
    | '/_public/loggedOut'
    | '/_public/login'
    | '/_public/privacy'
    | '/_public/tos'
    | '/_public/'
    | '/_public/users/$userId'
    | '/_public/users/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  PublicRoute: typeof PublicRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  PublicRoute: PublicRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated",
        "/_public"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/profile",
        "/_authenticated/reset-password"
      ]
    },
    "/_public": {
      "filePath": "_public.tsx",
      "children": [
        "/_public/about",
        "/_public/auth",
        "/_public/forgot-password",
        "/_public/loggedOut",
        "/_public/login",
        "/_public/privacy",
        "/_public/tos",
        "/_public/",
        "/_public/users/$userId",
        "/_public/users/"
      ]
    },
    "/_authenticated/profile": {
      "filePath": "_authenticated/profile.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/reset-password": {
      "filePath": "_authenticated/reset-password.tsx",
      "parent": "/_authenticated"
    },
    "/_public/about": {
      "filePath": "_public/about.tsx",
      "parent": "/_public"
    },
    "/_public/auth": {
      "filePath": "_public/auth.tsx",
      "parent": "/_public"
    },
    "/_public/forgot-password": {
      "filePath": "_public/forgot-password.tsx",
      "parent": "/_public"
    },
    "/_public/loggedOut": {
      "filePath": "_public/loggedOut.tsx",
      "parent": "/_public"
    },
    "/_public/login": {
      "filePath": "_public/login.tsx",
      "parent": "/_public"
    },
    "/_public/privacy": {
      "filePath": "_public/privacy.tsx",
      "parent": "/_public"
    },
    "/_public/tos": {
      "filePath": "_public/tos.tsx",
      "parent": "/_public"
    },
    "/_public/": {
      "filePath": "_public/index.tsx",
      "parent": "/_public"
    },
    "/_public/users/$userId": {
      "filePath": "_public/users/$userId.tsx",
      "parent": "/_public"
    },
    "/_public/users/": {
      "filePath": "_public/users/index.tsx",
      "parent": "/_public"
    }
  }
}
ROUTE_MANIFEST_END */
