import {
  Button,
  Container,
  Image,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import { useRouter } from "@tanstack/react-router";
import image from "#assets/not-found.png";

import Styles from "./styles.module.scss";

export const NotFound = () => {
  const router = useRouter();

  return (
    <Container className={Styles.root}>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={{ base: 40, sm: 80 }}>
        <Image className={Styles.mobileImage} src={image} />
        <div>
          <Title className={Styles.title}>Something is not right...</Title>
          <Text c="dimmed" size="lg">
            Page you are trying to open does not exist. You may have mistyped
            the address, or the page has been moved to another URL. If you think
            this is an error contact support.
          </Text>
          <Button
            className={Styles.control}
            mt="xl"
            onClick={() => router.history.push("/")}
            size="md"
            variant="outline"
          >
            Get back to home page
          </Button>
        </div>
        <Image className={Styles.desktopImage} src={image} />
      </SimpleGrid>
    </Container>
  );
};
