import { UsersWithBoardGames } from "#components/UsersWithBoardGames";
import { useGetUsersWithBoardGamesQuery } from "#graphql/graphql.ts";

export const UsersWithBoardGamesContainer = () => {
  const { data, error, isLoading } = useGetUsersWithBoardGamesQuery();

  if (isLoading || error || !data) {
    return null;
  }

  return <UsersWithBoardGames users={data.users} />;
};
