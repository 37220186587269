import { IconAlarm, IconUsers } from "@tabler/icons-react";
import type { FC } from "react";
import type { IBoardGamePlayerInfo } from "./interfaces";
import Styles from "./styles.module.scss";

export const BoardGamePlayerInfo: FC<IBoardGamePlayerInfo> = ({
  minPlayers,
  maxPlayers,
  recommendedPlayerCount,
  nonRecommendedPlayerCount,
  playTimeInMinutes,
  minAge,
}) => {
  const hasPlayerInfo = minPlayers != null && maxPlayers != null;
  const hasPlayTime = playTimeInMinutes != null;
  const hasAgeInfo = minAge != null;

  const renderPlaytime = () => {
    if (hasPlayTime) {
      return (
        <div className={Styles.iconset}>
          <IconAlarm />
          <span>{playTimeInMinutes} min.</span>
        </div>
      );
    }
  };

  const renderPlayerInfo = () => {
    if (hasPlayerInfo) {
      return (
        <div className={Styles.iconset}>
          <IconUsers />{" "}
          <span>
            {minPlayers} - {maxPlayers}
            {recommendedPlayerCount && (
              <>
                {" "}
                ({recommendedPlayerCount}
                {nonRecommendedPlayerCount &&
                  nonRecommendedPlayerCount !== recommendedPlayerCount && (
                    <>
                      {" "}
                      / <s>{nonRecommendedPlayerCount}</s>
                    </>
                  )}
                )
              </>
            )}
          </span>
        </div>
      );
    }
  };

  const renderAgeInfo = () => {
    if (hasAgeInfo) {
      return (
        <div className={Styles.iconset}>
          <div className={Styles.ageRating}>
            <span className={Styles.number}>{minAge}</span>
          </div>
          {/* <span className={Styles.ageRating}>{minAge}+</span> */}
          {/* <IconRating12Plus /> <span>{minAge}+</span> */}
        </div>
      );
    }
  };

  return (
    <div className={Styles.icons}>
      {renderPlayerInfo()}
      {renderPlaytime()}
      {renderAgeInfo()}
    </div>
  );
};
