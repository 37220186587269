import { Container, Paper, Stack, Text, Title } from "@mantine/core";

export const TosScreen = () => {
  return (
    <Container size="md" py="xl">
      <Paper p="xl" radius="md" withBorder={true}>
        <Stack>
          <Title order={1}>Terms of Service</Title>

          <Text>Last updated: {new Date().toLocaleDateString()}</Text>

          <Title order={2}>1. Terms</Title>
          <Text>
            By accessing our service, you agree to be bound by these terms of
            service, all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws.
          </Text>

          <Title order={2}>2. Use License</Title>
          <Text>
            Permission is granted to temporarily access the materials
            (information or software) on our website for personal,
            non-commercial transitory viewing only.
          </Text>

          <Title order={2}>3. Disclaimer</Title>
          <Text>
            The materials on our website are provided on an 'as is' basis. We
            make no warranties, expressed or implied, and hereby disclaim and
            negate all other warranties including, without limitation, implied
            warranties or conditions of merchantability, fitness for a
            particular purpose, or non-infringement of intellectual property or
            other violation of rights.
          </Text>

          <Title order={2}>4. Limitations</Title>
          <Text>
            In no event shall our company or its suppliers be liable for any
            damages (including, without limitation, damages for loss of data or
            profit, or due to business interruption) arising out of the use or
            inability to use our materials.
          </Text>

          <Title order={2}>5. Revisions and Errata</Title>
          <Text>
            The materials appearing on our website could include technical,
            typographical, or photographic errors. We do not warrant that any of
            the materials on our website are accurate, complete or current.
          </Text>

          <Title order={2}>6. Contact Us</Title>
          <Text>
            If you have any questions about these Terms of Service, please
            contact us.
          </Text>
        </Stack>
      </Paper>
    </Container>
  );
};
