import {
  ActionIcon,
  AppShell,
  Burger,
  Container,
  Drawer,
  Group,
  useComputedColorScheme,
  useMantineColorScheme,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconHome,
  IconInfoCircle,
  IconLock,
  IconLogout,
  IconMenu2,
  IconMoon,
  IconSun,
  IconUser,
  IconUsers,
} from "@tabler/icons-react";
import { useRouter } from "@tanstack/react-router";
import { useState } from "react";

import { useSignOut, useUserData } from "@nhost/react";
import { UserButton } from "#components/UserButton";
import { UserDrawer } from "./Components/UserDrawer";
import classes from "./styles.module.scss";

const links = [
  { label: "Home", path: "/", icon: IconHome },
  { label: "About", path: "/about", icon: IconInfoCircle },
  { label: "Users", path: "/users", icon: IconUsers },
  { label: "Profile", path: "/profile", icon: IconUser },
];

export const Header = () => {
  const router = useRouter();
  const [opened, { close, toggle }] = useDisclosure(false);
  const [active, setActive] = useState(router.state.location.pathname);
  const { setColorScheme } = useMantineColorScheme();
  const userData = useUserData();
  const { signOut } = useSignOut();
  const computedColorScheme = useComputedColorScheme("dark", {
    getInitialValueInEffect: true,
  });

  const handleSignOut = async () => {
    await signOut();
    router.history.push("/");
    close();
  };

  const bottomLinks = [
    { label: "Change Password", path: "/change-password", icon: IconLock },
    {
      label: "Logout",
      path: "/logout",
      icon: IconLogout,
      danger: true,
      onClick: handleSignOut,
    },
  ];

  const items = links.map((link) => (
    <a
      className={classes.link}
      data-active={active === link.path || undefined}
      href={link.path}
      key={link.label}
      onClick={(event) => {
        event.preventDefault();
        if (opened) {
          toggle();
        }

        router.history.push(link.path);
        setActive(link.path);
      }}
    >
      {link.label}
    </a>
  ));

  const mobileItems = links.map((link) => {
    const Icon = link.icon;
    return (
      <a
        className={classes.link}
        data-active={active === link.path || undefined}
        href={link.path}
        key={link.label}
        onClick={(event) => {
          event.preventDefault();
          close();
          router.history.push(link.path);
          setActive(link.path);
        }}
      >
        <Icon size={20} stroke={1.5} />
        {link.label}
      </a>
    );
  });

  const bottomItems = bottomLinks.map((link) => {
    const Icon = link.icon;
    return (
      <a
        className={classes.link}
        data-active={active === link.path || undefined}
        data-danger={link.danger || undefined}
        href={link.path}
        key={link.label}
        onClick={(event) => {
          event.preventDefault();
          if (link.onClick) {
            link.onClick();
          } else {
            close();
            router.history.push(link.path);
            setActive(link.path);
          }
        }}
      >
        <Icon size={20} stroke={1.5} />
        {link.label}
      </a>
    );
  });

  return (
    <>
      <AppShell.Header className={classes.header}>
        <Container className={classes.inner} fluid={true}>
          <div>BoardGameLibrary</div>
          <Group gap={5} visibleFrom="xs">
            {items}
          </Group>
          <Group gap={5}>
            <ActionIcon
              aria-label="Toggle color scheme"
              color={computedColorScheme === "dark" ? "yellow" : "grey"}
              onClick={() =>
                setColorScheme(
                  computedColorScheme === "light" ? "dark" : "light",
                )
              }
              variant={"filled"}
            >
              {computedColorScheme === "dark" ? (
                <IconSun stroke={1.5} />
              ) : (
                <IconMoon stroke={1.5} />
              )}
            </ActionIcon>
            <UserDrawer />
            <Burger
              hiddenFrom="xs"
              onClick={toggle}
              opened={opened}
              size="sm"
            />
          </Group>
        </Container>
      </AppShell.Header>
      <Drawer
        classNames={{
          root: classes.drawer,
          header: classes.drawerHeader,
        }}
        hiddenFrom="sm"
        onClose={close}
        opened={opened}
        padding="md"
        position="left"
        radius="md"
        size="16rem"
        title={
          <div className={classes.drawerTitle}>
            <IconMenu2 size={24} stroke={1.5} />
            Navigation
          </div>
        }
        withCloseButton={true}
        zIndex={1_000_000}
      >
        <nav className={classes.mobileNav}>
          <div>{mobileItems}</div>
          {userData && (
            <div className={classes.bottomSection}>
              <UserButton
                hideChevron={true}
                name={userData.displayName}
                url={userData.avatarUrl}
              />
              {bottomItems}
            </div>
          )}
        </nav>
      </Drawer>
    </>
  );
};
