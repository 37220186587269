import { AppShell } from "@mantine/core";
import type { FC, ReactElement } from "react";
import { Header } from "#components/Header";

interface ISidebarAndMain {
  mainContent: ReactElement;
}

export const TopNavAndMainContent: FC<ISidebarAndMain> = ({ mainContent }) => {
  return (
    <AppShell header={{ height: 60 }} padding="md">
      <Header />
      <AppShell.Main>{mainContent}</AppShell.Main>
    </AppShell>
  );
};
