import { useUserId } from "@nhost/react";
import type { FC } from "react";
import { useDebounceCallback } from "usehooks-ts";
import { useGetMechanicPercentagesForUserQuery } from "#graphql/graphql";

import { StatsDonut as StatsDonutComponent } from "#components/StatsDonut";
import { consolidateData } from "#components/StatsDonut/helpers";
import type { IStatsDonutData } from "#components/StatsDonut/interfaces.js";

export interface IStatsDonut {
  showLegend?: boolean;
  userUuid?: string;
}

export const StatsDonut: FC<IStatsDonut> = ({
  showLegend = true,
  userUuid,
}) => {
  const uuid = useUserId();

  if (!userUuid) {
    userUuid = uuid;
  }

  const { data, error, isLoading, refetch } =
    useGetMechanicPercentagesForUserQuery({
      percentageThreshold: 0,
      userUuid: userUuid,
    });

  const debouncedRefetch = useDebounceCallback(refetch, 1000);

  if (error) {
    debouncedRefetch();
  }

  const consolidatedData: IStatsDonutData[] = consolidateData(data);
  if (!consolidatedData || consolidatedData.length === 0) {
    return null;
  }

  return <StatsDonutComponent data={consolidatedData} isLoading={isLoading} />;
};
