import { createFileRoute, Outlet } from "@tanstack/react-router";
import { Suspense } from "react";
import { TanStackRouterDevtools } from "#/components/TanStackRouterDevTools";

const Component = () => {
  return (
    <>
      <Outlet />
      <Suspense fallback={null}>
        <TanStackRouterDevtools />
      </Suspense>
    </>
  );
};

export const Route = createFileRoute("/_public")({
  component: Component,
});
