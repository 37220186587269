import { useUserData } from "@nhost/react";
import type { FC } from "react";
import type { IGetBoardGamesQuery } from "#graphql/graphql.ts";

import { StatsDonut } from "#containers/StatsDonut";

interface ISideNav {
  user?: IGetBoardGamesQuery["user"];
}

export const StatsBarContainer: FC<ISideNav> = ({ user }) => {
  const userData = useUserData();
  if (!user) {
    user = userData;
  }

  return <StatsDonut userUuid={user?.id} />;
};
