import {
  Alert,
  Anchor,
  Button,
  Checkbox,
  Container,
  Divider,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { upperFirst, useToggle } from "@mantine/hooks";
import { useSignInEmailPassword, useSignUpEmailPassword } from "@nhost/react";
import { IconAlertCircle, IconBrandGoogle } from "@tabler/icons-react";
import { Link, useNavigate, useRouter } from "@tanstack/react-router";
import { type FC, useEffect, useState } from "react";
import { CheckMail } from "#components/CheckMail";
import { nhost } from "#lib/nhost";

const EMAIL_REGEX = /^\S+@\S+$/;
export const LoginScreen: FC = () => {
  const router = useRouter();
  const initialType =
    window.location.hash === "#register" ? "register" : "login";
  const [type, toggle] = useToggle([
    initialType,
    initialType === "login" ? "register" : "login",
  ]);
  const {
    error: signUpError,
    isLoading,
    needsEmailVerification: signUpNeedsEmailVerification,
    signUpEmailPassword,
  } = useSignUpEmailPassword();
  const { error, needsEmailVerification, signInEmailPassword } =
    useSignInEmailPassword();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  if (error || signUpError) {
    console.error("We ran into a signup error", error || signUpError);
  }

  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      password: "",
      terms: false,
    },
    validate: {
      email: (value: string) =>
        EMAIL_REGEX.test(value) ? null : "Invalid email",
      password: (value: string) =>
        value.length <= 6
          ? "Password should include at least 6 characters"
          : null,
      terms: (value: boolean) =>
        type === "register" && !value
          ? "You must accept the terms and conditions"
          : null,
    },
  });

  useEffect(() => {
    if (!error || error.status !== 401 || form.errors.auth) {
      return;
    }

    form.setFieldError("auth", "Invalid email or password");
  }, [error, form]);

  useEffect(() => {
    if (!needsEmailVerification || form.errors.emailVerification) {
      return;
    }

    form.setFieldError(
      "emailVerification",
      "Please check your email for a verification link",
    );
  }, [needsEmailVerification, form]);

  const redirectToProfile = () => {
    router.history.push("/profile");
  };

  const handleGoogleSignIn = async () => {
    try {
      await nhost.auth.signIn({
        provider: "google",
      });
    } catch (error) {
      console.error("Google sign-in error:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    form.clearErrors();

    if (!EMAIL_REGEX.test(email)) {
      form.setErrors({
        email: "Please enter a valid email address",
      });
      return;
    }

    console.log("Signing in...");
    const { isError, error } = await signInEmailPassword(email, password);
    console.log(isError, error);

    if (isError) {
      form.setErrors({
        password: "Incorrect password",
      });
      return;
    }

    nhost.auth.onAuthStateChanged((_, session) => {
      if (session != null) {
        redirectToProfile();
      }
    });
  };

  if (needsEmailVerification || signUpNeedsEmailVerification) {
    return <CheckMail />;
  }

  return (
    <div
      style={{
        alignItems: "center",
        backgroundImage:
          "url(https://res.cloudinary.com/govannon/image/upload/q_auto/v1734967250/bgl/background.png)",
        backgroundSize: "cover",
        display: "flex",
        flex: 1,
        height: "100vh",
        justifyContent: "center",
      }}
    >
      <Container size={"xs"}>
        <Paper p="xl" radius="md" withBorder={true}>
          <Text fw={500} size="lg" ta="center" mb="md">
            Welcome to BoardGameLibrary
          </Text>

          <Button
            fullWidth={true}
            leftSection={<IconBrandGoogle size={20} />}
            variant="default"
            onClick={handleGoogleSignIn}
          >
            Continue with Google
          </Button>

          <Divider
            label="Or continue with email"
            labelPosition="center"
            my="lg"
          />

          <form onSubmit={handleSubmit}>
            <Stack>
              {type === "register" && (
                <TextInput
                  label="Name"
                  onChange={(event) =>
                    form.setFieldValue("name", event.currentTarget.value)
                  }
                  placeholder="Your name"
                  radius="md"
                  value={form.values.name}
                />
              )}

              <TextInput
                error={form.errors.email}
                label="Email"
                placeholder="example@me.com"
                radius="md"
                required={true}
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <PasswordInput
                error={form.errors.password}
                label="Password"
                placeholder="Your password"
                radius="md"
                required={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {type === "login" && (
                <Anchor
                  component={Link}
                  to="/forgot-password"
                  size="xs"
                  c="dimmed"
                  style={{ display: "block", textAlign: "right" }}
                >
                  Forgot your password?
                </Anchor>
              )}

              {form.errors.auth && (
                <Alert color="red" icon={<IconAlertCircle size={16} />}>
                  {form.errors.auth}
                </Alert>
              )}

              {form.errors.emailValidation && (
                <Text c="red" size="sm">
                  Must verify email
                </Text>
              )}

              {type === "register" && (
                <Checkbox
                  checked={form.values.terms}
                  label={
                    <Text size="sm">
                      I accept the{" "}
                      <Anchor href="/tos" target="_blank" size="sm">
                        terms of service
                      </Anchor>{" "}
                      and{" "}
                      <Anchor href="/privacy" target="_blank" size="sm">
                        privacy policy
                      </Anchor>
                    </Text>
                  }
                  onChange={(event) =>
                    form.setFieldValue("terms", event.currentTarget.checked)
                  }
                  required={true}
                />
              )}
            </Stack>

            <Group justify="space-between" mt="xl">
              <Anchor
                c="dimmed"
                component="button"
                onClick={() => toggle()}
                size="xs"
                type="button"
              >
                {type === "register"
                  ? "Already have an account? Login"
                  : "Don't have an account? Register"}
              </Anchor>

              <Button disabled={isLoading} radius="xl" type="submit">
                {upperFirst(type)}
              </Button>
            </Group>
          </form>
        </Paper>
      </Container>
    </div>
  );
};
